const studentAdvantages = [
  "Удобный интерфейс для изучения материала",
  "Возможность задавать вопросы преподавателям",
  "Возможность проходить тесты и получить оценку",
  "Видеть прогресс обучения",
  "Обучение на любых устройствах (смартфон, планшет, компьютер)",
  "Рейтинговая система",
  "Cертификаты после прохождения курса",
];

const StudentAdvantage = () => {
  return (
    <section className="bg-gray-50" id="advantage">
      <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
        <div className="items-center gap-8 lg:flex xl:gap-16">
          <div className="text-gray-500 sm:text-lg lg:w-2/5">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900">
              Преимущества для студентов
            </h2>

            <ul className="pt-8 space-y-5 border-t border-gray-200 my-7">
              {studentAdvantages.map((advantage) => (
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-teal-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900">
                    {advantage}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div class="lg:w-3/5">
            <img
              className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src="./topcourse-student.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudentAdvantage;
