import { Link } from "react-router-dom";

const PlPrices = ({ showContactUsForm }) => {

  const plans = [
    {
      name: "Тариф Базовый",
      price: 29000,
      features: [
        "Создание безграничного количество курсов",
        "Рейтинговая система & Геймификация",
        "Техническая поддержка вашей платформы",
        "Загрузка материалов в формате PDF, медиа, видео, аудио",
        "Сертификаты после прохождения курса",
        "Обучение на любых устройствах (смартфон, планшет, компьютер)",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",        
      ]
    },
    {
      name: "Тариф Pro",
      price: 49000,
      features: [
        "Создание безграничного количество курсов",
        "Рейтинговая система & Геймификация",
        "Техническая поддержка вашей платформы",
        "Загрузка материалов в формате PDF, медиа, видео, аудио",
        "Сертификаты после прохождения курса",
        "Обучение на любых устройствах (смартфон, планшет, компьютер)",
        "Настройка дизайна вашей платформы",
        "Установка системы",
        "Аналитика",
        "Научим вас использовать платформу",
      ]
    },
  ]

  return (
    <section className="bg-white dark:bg-gray-900" id="prices">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
        <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
          <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Ценность и стоимость
          </h2>
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            5 дней бесплатно, тестовый период с полным функционалом
          </p>
        </div>
        <div className="flexr justify-center">
          {plans.map((plan) => (
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 className="mb-4 text-2xl font-semibold">{plan.name}</h3>
              <ul className="mb-8 space-y-4 text-left">
                {plan.features.map((feature) => (
                  <li className="flex items-center space-x-3">
                    {feature && <><svg
                      className="flex-shrink-0 w-5 h-5 text-teal-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                      <span>{feature}</span></>}
                  </li>
                ))}
              </ul>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-3xl font-extrabold">{plan.price} ₸</span>
                <span className="text-gray-500 dark:text-gray-400">/мес</span>
              </div>
              <Link
                to={"#"}
                className="text-white bg-teal-600 hover:bg-teal-700 focus:ring-4 focus:ring-teal-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={showContactUsForm}
              >
                Оставить заявку
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PlPrices;
