import React from 'react'

export const Footer = () => {
    return (
        <div className="flex flex-col justify-center items-center py-8">
            <div>
                <span>ТОО "HajjTravel". Мы находимся по адресу: г. Алматы, ул. Утепова, 10, офис 1231</span>
            </div>
            <div>
                <span>2021 - {new Date().getFullYear()}.</span>
                <span> Все права защищены.</span>
            </div>
        </div>
    )
}
