import PlMainInfo from "./components/PlMainInfo";
import PlPrices from "./components/PlPrices";
import PlFooter from "./components/PlFooter";
import PlAskedQuestions from "./components/PlAskedQuestions";
import PlAdvantage from "./components/PlAdvantage";
import PlHeader from "./components/PlHeader";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, Button } from "@mui/material";
import { useState } from "react";
import StudentAdvantage from "./components/StudentAdvantage";
import { Footer } from "./components/Footer";

const PlatformLanding = () => {
  const [isContactUsFormOpen, setIsContactUsFormOpen] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);

    fetch('https://api.hajjtravel.kz/api/public/requests', {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(response => response.json())
      .then(data => {
        console.log(data);
        alert('Заявка отправлена, мы свяжемся с вами в ближайшее время');
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Ошибка при отправке заявки, попробуйте позже');
      }).finally(() => {
        setIsContactUsFormOpen(false);
      });

  };

  const showContactUsForm = () => {
    setIsContactUsFormOpen(true);
  };

  return (
    <>
      <PlHeader />
      <PlMainInfo showContactUsForm={showContactUsForm} />
      <PlAdvantage />
      <StudentAdvantage />
      <PlPrices showContactUsForm={showContactUsForm} />
      <PlAskedQuestions />
      <Footer />
      <Dialog open={isContactUsFormOpen} onClose={() => setIsContactUsFormOpen(false)}>
        <DialogTitle>Обратная связь</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Имя"
                name="name"
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Телефон"
                name="phone"
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Сообщение"
                name="message"
                required
                fullWidth
                multiline
                rows={4}
              />
              <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
                Оставить заявку
              </Button>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PlatformLanding;
